import idx from 'idx';
import fetch from 'isomorphic-fetch';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { from, split } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { onError, ErrorResponse } from 'apollo-link-error';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import logout from '@/helpers/logout';
import { SessionState } from '@/contexts/SessionContext';

import resolvers from './resolvers';
import introspectionQueryResultData from './generated/schema.json';
import { getClientUri, getClientWsUri, useClient } from '../clients';

const authLink = (session: SessionState) =>
  setContext(async () => {
    const token = await session.getToken();

    if (token) {
      return {
        headers: { Authorization: `Bearer ${token}` },
      };
    }
  });

const httpLink = (uri: string) =>
  new HttpLink({
    uri,
    fetch,
  });

const errorLink = () =>
  onError((error: ErrorResponse) => {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );

    if (networkError) {
      console.warn(`[Network error]: ${networkError}`);
    }

    if (networkError) {
      const is400 = networkError.statusCode === 400;
      const isInvalidToken =
        idx(error, (_) => _.graphQLErrors[0].message) === 'Invalid OKTA access token';

      if (is400 && isInvalidToken) {
        logout();
      }
    }
  });

const wsLink = (session: SessionState, clientWsUri: string) =>
  new WebSocketLink({
    uri: clientWsUri as string,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        const token = await session.getToken();
        if (token) {
          return { accessToken: token };
        }
        return {};
      },
    },
  });

const link = (session: SessionState) => {
  const { id, uri, wsUri } = useClient();
  const clientUri = getClientUri(id);
  const clientWsUri = getClientWsUri(id);
  return typeof window !== 'undefined'
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        wsLink(session, wsUri),
        httpLink(uri)
      )
    : httpLink;
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export const client = (session: SessionState) =>
  new ApolloClient({
    resolvers,
    link: from([
      require('apollo-link-logger').default,
      authLink(session),
      errorLink(),
      link(session),
    ]),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (object) => object.id,
    }),
    connectToDevTools: true,
  });
