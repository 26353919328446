import React, { FC, createContext, useContext } from 'react';
import { Location, LocationContext } from '@reach/router';

const ReachRouterHooksContext = createContext<LocationContext>({} as LocationContext);

export const ReachRouterHooksContextProvider: FC = props => {
  return (
    <Location>
      {value => (
        <ReachRouterHooksContext.Provider value={value}>
          {props.children}
        </ReachRouterHooksContext.Provider>
      )}
    </Location>
  )
};

export const useReachRouterLocation = () => {
  const context = useContext(ReachRouterHooksContext)
  return context
}

export default ReachRouterHooksContext;

