import window from 'global/window';
import dangerouslyExposeInDevelopment from '@/helpers/dangerouslyExposeInDevelopment';

let okta: any; // eslint-disable-line @typescript-eslint/no-explicit-any

export const createOkta = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (typeof navigator === 'undefined') {
    return null;
  }

  const OktaSignIn = window.OktaSignIn;

  if (!OktaSignIn) {
    return null;
  }

  var userAgent = window.navigator.userAgent;

  // Safari does not support cross-domain cookies so we need to make a few
  // redirects instead of handling sign-in within the application
  const display = (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) ? 'page' : 'popup';

  const okta = new OktaSignIn({
    baseUrl: 'https://dinotech.okta-emea.com',
    clientId: process.env.OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/`,
    language: 'en',
    authParams: {
      display,
      issuer: 'https://dinotech.okta-emea.com/oauth2/default',
      responseType: ['token', 'id_token'],
      scopes: ['openid', 'email', 'groups'],
    },
  });

  dangerouslyExposeInDevelopment({ okta });

  return okta;
};

export const getOkta = () => {
  if (!okta) {
    okta = createOkta();
  }

  return okta;
};
