import 'typeface-lato';
import 'typeface-oswald';
import '@/modules/typeface-avenir';

import { setConfig } from 'react-hot-loader';

// see https://github.com/gaearon/react-hot-loader/issues/1294
setConfig({
  reloadHooks: false,
});
