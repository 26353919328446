import { withStyles, createStyles } from '@material-ui/styles';
import { appBackground } from '@/theme/settings'

export default withStyles(
  createStyles({
    '@global': {
      html: {
        color: 'white',
        fontFamily: 'Lato, sans-serif',
      },
      body: {
        margin: 0,
        padding: 0,
        minHeight: '100vh',
        background: appBackground,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: 'center top',
        backgroundAttachment: 'fixed',

      },
      'a, a:hover, a:focus, a:active': {
        textDecoration: 'none',
        color: 'inherit',
      },
    },
  })
)(() => null);
