import { clearRedirect } from '@/helpers/redirect'
import { getOkta } from './okta';

export default function logout() {
  const okta = getOkta()

  if (!okta) {
    return;
  }

  okta.authClient.session.close().then(() => {
    return okta.authClient.tokenManager.clear();
  }).then(() => {
    clearRedirect();
    window.location.href = '/login';
  }).catch(err => {
    console.error('Could not logout', err);
  })
}
