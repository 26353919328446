export const placeholder = {
  fontFamily: 'Avenir, Oswald',
  fontSize: '0.75em',
  fontWeight: 500,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 2,
  letterSpacing: 'normal',
  color: '#a0a0a0',
};

export const appBackground = 'radial-gradient(circle at top center, #3D1D43, #0d1933)'

export const colors = {
  active: '#ff2c65',
  darkPanel: '#0d1933',
  lightPanel: '#5f3461',
  darkElement: '#091122'
};
