import React, { FC, createContext, useState } from 'react';

const initialState = {
  loading: false,
  setLoading: (_: boolean) => {},
};

export type AppState = typeof initialState;

const AppContext = createContext(initialState);

export const AppContextProvider: FC = props => {
  const [loading, setLoading] = useState(initialState.loading);

  const context = {
    loading,
    setLoading,
  };

  return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>;
};

export default AppContext;
