import createPersistedState from 'use-persisted-state';

export type Client = {
  id: string;
  name: string;
  uri: string;
  wsUri: string;
  org: string;
};

export type Clients = Array<Client>;

export const clients = [
  {
    id: 'miuratech',
    org: 'MT',
    name: 'MiuraTech',
    uri: process.env.DINO_GRAPHQL_URL,
    wsUri: process.env.DINO_GRAPHQL_WS_URL,
  },
  {
    id: 'infiniza',
    org: 'IN',
    name: 'Infiniza',
    uri: process.env.INFINIZA_GRAPHQL_URL,
    wsUri: process.env.INFINIZA_GRAPHQL_WS_URL,
  },
];

export const getClient = (clientID: string) => {
  return clients.find((client) => client.id === clientID);
};

export const getOrg = (clientID: string) => {
  return getClient(clientID)?.org;
};

export const getClientUri = (clientID: string) => {
  return getClient(clientID)?.uri;
};

export const getClientWsUri = (clientID: string) => {
  return getClient(clientID)?.wsUri;
};

const useClientState = createPersistedState('clientId');

export const useClient = (initialClientId?: any) => {
  const [clientId, setClientId] = useClientState(initialClientId);

  if (!clientId) {
    setClientId(clients[0].id);
  }
  const uri = getClientUri(clientId);
  const wsUri = getClientWsUri(clientId);
  const org = getOrg(clientId);

  return {
    id: clientId,
    uri,
    wsUri,
    org,
    setClientId: (value: any) => setClientId(value),
  };
};
