import React, { FC } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { globalHistory } from '@reach/router';
import { ApolloProvider } from '@apollo/react-common';
import { SnackbarProvider } from 'notistack';
import { QueryParamProvider } from 'use-query-params';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from '@/containers/GlobalStyles';
import { theme } from '@/theme/mui';
import { client } from '@/apollo';
import { AppContextProvider } from '@/contexts/AppContext';
import { ReachRouterHooksContextProvider } from '@/modules/reach-router-hooks';
import { SessionContextProvider, SessionState } from '@/contexts/SessionContext';

const TopLayout: FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <QueryParamProvider reachHistory={globalHistory}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <SnackbarProvider>
              <ReachRouterHooksContextProvider>
                <SessionContextProvider>
                  {(session: SessionState) => {
                    const apolloClient = client(session);
                    return (
                      <ApolloProvider client={apolloClient}>
                        <>
                          <CssBaseline />
                          <GlobalStyles />
                          {children}
                        </>
                      </ApolloProvider>
                    );
                  }}
                </SessionContextProvider>
              </ReachRouterHooksContextProvider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </QueryParamProvider>
      </AppContextProvider>
    </ThemeProvider>
  );
};

export default TopLayout;
