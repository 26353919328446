import Cookie from 'js-cookie';
import serialize from 'serialize-javascript';

const REDIR_COOKIE_NAME = 'redir'

function deserialise(serializedJavascript: string) {
  return eval('(' + serializedJavascript + ')');
}

function normalize (path: string) {
  return path.replace(/\/$/, '') + '/'
}

export const redirectTo = (input: string) => {
  const location = window.location
  const pathname = normalize(location.href)
  const destination = normalize(input)

  if (pathname === destination) {
    return
  }

  Cookie.set(REDIR_COOKIE_NAME, serialize(pathname));

  location.href = destination;
}

export const navigateToRedirect = (defaults: string) => {
  const cookie = Cookie.get(REDIR_COOKIE_NAME);
  const location = window.location;

  let redir = defaults;

  try {
    if (cookie) {
      redir = deserialise(cookie);
    }
  } catch (e) {
    console.warn('Cannot deserialize redirect cookie', cookie, e);
  }

  Cookie.remove(REDIR_COOKIE_NAME);

  if (redir) {
    location.href = redir;
  }
};

export const clearRedirect = () => {
  Cookie.remove(REDIR_COOKIE_NAME)
}
