/* eslint-disable @typescript-eslint/ban-ts-ignore, @typescript-eslint/no-explicit-any */

export default function dangerouslyExposeInDevelopment(obj: any) {
  if (typeof window === `undefined`) {
    return; // we're on a BE - silently don't expose anything
  }

  if (process.env.NODE_ENV === 'development') {
    if (!['localhost'].includes(window.location.hostname)) {
      console.warn(
        `dangerouslyExposeInDevelopment: skipping since NODE_ENV is "development" but hostname isn't "localhost"`
      );
    } else {
      // @ts-ignore
      Object.assign((window.devapp = window.devapp || {}), obj);
    }
  }
}
