import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';

import dangerouslyExposeInDevelopment from '@/helpers/dangerouslyExposeInDevelopment';

import { placeholder } from './settings';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const containedButtonDisabled = {
  color: 'rgba(150, 150, 150, 0.8)',
  backgroundColor: 'rgba(100, 100, 100, 0.5)',
  backgroundImage: 'none',
};

export const theme = createMuiTheme({
  palette: {
    divider: '#5f3461',
    primary: {
      main: '#0d1933',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f77874',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#a0a0a0',
    },
    action: {
      selected: '#ff2c65',
    },
    background: {
      paper: '#2f294f',
    },
  },
  typography: {
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: '-0.06px',
    },
    h4: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      fontFamily: 'Oswald',
      letterSpacing: '4.8px',
      textAlign: 'left',
      fontWeight: 400,
    } as TypographyStyleOptions,
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '14px',
      fontWeight: 900,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.71,
      letterSpacing: '1.4px',
      textAlign: 'left',
    } as TypographyStyleOptions,
    body1: {
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.71,
      letterSpacing: 'normal',
      textAlign: 'left',
    } as TypographyStyleOptions,
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '-0.04px',
    } as TypographyStyleOptions,
    overline: {
      fontSize: 11,
    } as TypographyStyleOptions,
  },
  overrides: {
    MuiMenu: {
      paper: {
        backgroundColor: '#091122',
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$disabled': {
          color: 'rgba(189,189,189, 0.5)',
        },
        '&$disabled + $track': {
          backgroundColor: 'rgb(9,17,34, 0.4)',
          opacity: 1,
        },
      },
    },
    MuiInputBase: {
      input: {
        '&$disabled': {
          color: 'rgba(160, 160, 160, 0.5)',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid #fff`,
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgba(0, 0, 0, 0.33)',
      },
    },
    MuiSelect: {
      icon: {
        color: '#fff',
      },
      outlined: {
        paddingTop: 8,
        paddingRight: 30,
        paddingBottom: 8,
      },
    },
    MuiFormControlLabel: {
      label: {
        '&$disabled': {
          color: 'rgba(160, 160, 160, 0.45)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
        '&$focused': {
          color: '#fff',
        },
        '&$disabled': {
          color: 'rgba(160, 160, 160, 0.45)',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1rem',
        '&$focused': {
          color: '#fff',
        },
      },
      filled: {
        '&$shrink': {
          transform: 'translate(0, 1.5px) scale(0.75)',
        },
      },
      formControl: {
        top: 0,
        left: 0,
        transform: 'translate(0, 1.5px) scale(0.75)',
      },
    },
    MuiFilledInput: {
      root: {
        marginTop: 20,
        borderRadius: 4,
        backgroundColor: '#091122',
        '&$disabled': {
          backgroundColor: 'rgba(9, 17, 34, 0.5)',
        },
      },
      input: {
        paddingTop: 10,
        '&::-webkit-input-placeholder': placeholder,
        '&::-moz-placeholder': placeholder, // Firefox 19+
        '&:-ms-input-placeholder': placeholder, // IE 11
        '&::-ms-input-placeholder': placeholder, // Edge
      },
      multiline: {
        padding: '10px 12px',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#fff',
      },
      root: {
        contained: {
          '&$disabled': {
            color: 'rgba(150, 150, 150, 0.8)',
            backgroundColor: 'rgba(100, 100, 100, 0.5)',
            backgroundImage: 'none',
          },
        },
        '&$containedPrimary': {
          backgroundImage: 'linear-gradient(84deg, #ff4a77, #f77874)',
          '&$disabled': containedButtonDisabled,
        },
        '&$containedSecondary': {
          color: '#fff',
          backgroundImage: 'linear-gradient(77deg, #73f5a5, #2bdeff);',
          '&$disabled': containedButtonDisabled,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '24px 12px',
        fontSize: 12,
        objectFit: 'contain',
        fontStyle: 'normal',
        fontFamily: 'Avenir',
        fontWeight: 500,
        lineHeight: 1.33,
        fontStretch: 'normal',
        borderBottom: 'none',
        letterSpacing: 'normal',
      },
      head: {
        color: '#fff',
        fontSize: '14px',
        objectFit: 'contain',
        fontStyle: 'normal',
        fontFamily: 'Avenir',
        fontWeight: 900,
        lineHeight: 1.71,
        fontStretch: 'normal',
        letterSpacing: 'normal',
      },
    },
    MuiTableRow: {
      head: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#091122',
          boxShadow: '0 16px 24px 0 rgba(0, 0, 0, 0.48)',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#fff',
        '&$disabled': {
          color: 'rgba(255, 255, 255, 0.35)',
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#ff2c65',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#fff',
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        color: '#fff',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
    },
    MuiAutocomplete: {
      popupIndicator: {
        color: '#fff',
      },
      clearIndicator: {
        color: '#fff',
      },
    },
  },
});

// fix https://monosnap.com/file/BwMwz80sUX0iTEdDccKLTihrM2uEO8
// see https://github.com/mui-org/material-ui/issues/8183#issuecomment-329481035
const originalGetContrastText = theme.palette.getContrastText;
theme.palette.getContrastText = (color) => {
  if (color === theme.palette.grey[100]) {
    return theme.palette.common.white;
  }

  if (color === theme.palette.grey[300]) {
    return theme.palette.common.white;
  }

  return originalGetContrastText(color);
};

dangerouslyExposeInDevelopment({ theme });
