import size from 'lodash-es/size';
import range from 'lodash-es/range';
import random from 'lodash-es/random';
import format from 'date-fns/format';
import memoize from 'lodash-es/memoize';

import { TransactionType, BrandEnum } from '@/constants';

const TIMEOUT = 500;
const generateTransactions = memoize(playerId =>
  range(20).map((_, i) => ({
    playerId,
    __typename: 'TransactionSearchItem',
    id: '08438946290' + i,
    brand: 'brand',
    gameId: '18438946290' + i,
    comment: 'User accidentally withdrew wrong amount from account ' + i,
    currency: 'SEK',
    gameRoundId: '18438946290' + i,
    processedAt: 'Wed Jun 19 2019 14:15:29 GMT+0300 (Eastern European Summer Time)',
    gameProvider: 'gameProvider',
    openingBalance: random(2000, 15000),
    closingBalance: random(2000, 15000),
    paymentMethod: 'paymentMethod',
    transactionId: '18438946290' + i,
    transactionType: Object.values(TransactionType)[random(0, size(TransactionType) - 1)],
    gameRoundStatus: 'GameRoundStatus',
    extTransactionRef: 'extTransactionRef',
    transactionStatus: 'transactionStatus',
  }))
);

export default {
  Query: {
    player(obj, args) {
      return { ...args, __typename: 'Player' };
    },
  },
  Player: {
    transactions: (obj, args) => {
      let transactions = generateTransactions(obj.playerId);

      if (args.transactionTypes) {
        transactions = transactions.filter(t => {
          return args.transactionTypes.includes(t.transactionType);
        });
      }

      const result = {
        __typename: 'TransactionSearchItemConnection',
        edges: transactions.map(node => ({
          __typename: 'TransactionSearchItemEdge',
          node,
        })),
      };

      return new Promise(resolve => {
        {
          setTimeout(() => resolve(result), TIMEOUT);
        }
      });
    },
    linkedAccounts: () => {
      const accounts = range(5).map((_, i) => ({
        brand: Object.values(BrandEnum)[random(0, size(BrandEnum) - 1)],
        status: 'status',
        firstName: 'firstName ' + i,
        lastName: 'lastName ' + i,
        playerId: 'zFZSoQAHqKi3FBbkTvOz0AykR8PidQ',
        birthDate: format(new Date(), 'yyyy-MM-dd'),
        numberOfLinks: random(0, 5),
        __typename: 'LinkedAccounts',
      }));

      return accounts;
    },
  },
};
